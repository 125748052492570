import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import InternalImage from "../components/InternalImage";
import PageBanner from '../components/PageBanner';
import Members from '../components/Members';
import Button from '../components/Button';
import FooterSponsors from '../components/FooterSponsors';
import PressReviews from '../components/PressReviews';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageBanner styleId={1} title={"L'association"} description={"L’association Toulouse DataViz a pour but de promouvoir et de diffuser la culture de l’analyse et de la visualisation des données."} mdxType="PageBanner" />
    <section id="one">
      <div className="inner">
        <h1>{`Notre ambition`}</h1>
        <ul>
          <li parentName="ul">{`Apprendre à découvrir les vérités enfouies dans les open data,`}</li>
          <li parentName="ul">{`Déjouer les visualisations trompeuses`}</li>
          <li parentName="ul">{`Convaincre avec des graphiques.`}</li>
        </ul>
        <p>{`L’association `}<strong parentName="p">{`Toulouse DataViz`}</strong>{` a pour but de promouvoir et de diffuser la culture de l’analyse et de la visualisation des données.`}</p>
        <p>{`Il y a longtemps que l’on a remarqué qu’il était plus facile de percevoir et comprendre les données sous une forme visuelle: « Un diagramme vaut mille mots ». Mais il ne suffit pas de dessiner de jolis camemberts colorés pour bien communiquer des données.
La visualisation de données est un véritable langage avec ses règles, sa grammaire et ses exceptions, utiles non seulement pour élaborer des messages efficaces mais aussi pour être capable de les décoder et éviter les pièges.`}</p>
        <p>{`Et les données deviennent omniprésentes: des institutions publiques, organisations internationales et certaines entreprises privées mettent leurs données dans le domaine public `}<em parentName="p">{`(open data)`}</em>{`. Des quantités astronomiques de données libres sont désormais disponibles.
Certains citoyens, journalistes, étudiants ou professionnels s’en emparent pour toutes sortes d’usages. Les données sont partout et la maitrise du langage de la visualisation de données devient incontournable pour rechercher de l’information et la communiquer.`}</p>
        <p><strong parentName="p">{`Notre ambition est d’apprendre à découvrir les vérités enfouies dans les open data, à déjouer les visualisations trompeuses et à convaincre avec des graphiques.`}</strong></p>
      </div>
    </section>
    <section id="two" className="spotlights">
      <section>
        <div className="inner">
          <h1>{`L'équipe`}</h1>
          <Members mdxType="Members" />
        </div>
      </section>
      <section>
        <div className="inner">
          <h1>{`On parle de nous !`}</h1>
  <PressReviews mdxType="PressReviews" />
        </div>
      </section>
      <section>
        <div className="inner">
          <h1>{`Notre offre`}</h1>
          <p>{`L'association Toulouse DataViz est soutenue et parrainée par des entreprises locales et par des acteurs institutionnels de l'Occitanie. `}</p>
          <p>{`Pour les associations à but non lucratif et les écoles, l'association fournit au cas par cas des prestations à des conditions particulières. `}</p>
          <p>{`Nous offrons des conseils et apportons des services dans notre cadre associatif. Si nécessaire  nous vous aiguillons vers des professionnels de notre éco-système.
Nous développons des tableaux de bord et explorateurs de données sur le web.
Nous proposons des services complets  à destination des entreprises et  des collectivités.`}</p>
          <Button link={"/offres"} type={'internal'} text={"Découvrez nos offres"} mdxType="Button" />
        </div>
      </section>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      